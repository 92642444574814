/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
export function acpInterstitialsAppStates($stateProvider: any) {
  'ngInject';

  $stateProvider
    .state('interstitials', {
      parent: 'root',
      redirectTo: '.landing',
      url: '/interstitials',
      templateUrl: 'apps/interstitials/templates/acp-interstitials.ng.html',
      data: {
        permissions: {
          only: 'isInterstitialsRedesignEnabled',
          redirectTo: {
            isInterstitialsRedesignEnabled: 'dashboard'
          }
        }
      },
      resolve: {
        module($ocLazyLoad) {
          'ngInject';

          return import(/* webpackChunkName: "acp.apps.interstitials" */ './index').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('interstitials.landing', {
      template: `<acp-interstitials-landing-component></acp-interstitials-landing-component>`
    });
}
